<template>
    <div id="sidebar" class="sidebarCheckoutPublic" data-e2e="sidebarCheckoutPublic">

        <div class="checkoutBack" @click="mixinGoBack()">
            <!-- posso arrivare qui da dettaglio struttura o da catalogo, meglio evitare di incartare logiche solo per cambiare il copy di un pulsante: usiamo un generico "torna indietro"
            <router-link :to="{ name: 'property', params: { propertyId: bookingData.hotelId, propertyName: bookingData.hotelName, propertyBand: bookingData.band }}">
            <BtnBack :text="'Torna alla struttura'" />
            </router-link>-->
            <BtnBack :text="'Torna indietro'" />
        </div>

        <div class="sidebar-inner">
            <div id="sidebarCheckout" :class="(mixinGetIntegrationInfo().mode == 'boxset' ? 'hasBackground' : '')">

                <!-- Credito welfare/info utente/carrello -->
                <div class="sidebarStep bgGray">
                    <h5 class="sidebarStepTitle">
                        <span class="checkoutDynamicBlockTitle justify-content-center">Il tuo carrello</span>
                    </h5>
                    
                    <hr />
                    
                    <!-- riepilogo carrello boxset -->
                    <div v-if="mixinGetIntegrationInfo().mode == 'boxset'">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                Salabam {{bookingData.focus | capitalize}} {{bookingData.band | capitalize}} <span>{{dates.nights}} <span v-if="dates.nights > 1">notti</span><span v-else>notte</span></span>
                            </span>

                            <div class="qty" v-if="$config.guiSettings.giftCardsEnabled">
                                <b-form-input size="sm" type="number" v-model="qty" :placeholder="qty.toString()" :min="1" :max='$config.guiSettings.boxsetOrderMaxQty'></b-form-input>
                            </div>

                            <span v-else>{{bookingData.public_price}} €</span><!--uso per sicurezza bookingData.public_price, ma potrei usare anche price che è calcolato come bookingData.public_price*qty ed è reattivo -->
                        </div>
                            
                        <hr class="mt-2 mb-2" />

                        <div class="d-flex justify-content-between">
                            <strong>Totale</strong>
                            <strong>{{price}} €</strong>
                        </div>
                    </div>

                    <!-- riepilogo carrello prenotazione -->
                    <div v-if="mixinGetIntegrationInfo().mode == 'booking'">
                        <div>
                            <SvgBedBlack class="mr-2 mb-1" /> {{bookingData.hotelName}}
                            <br>
                            {{bookingData.numberOfAdults}} <span v-if="bookingData.numberOfAdults == 1">Adulto</span><span v-else>Adulti</span>,
                            <span v-if="bookingData.childs.length > 0">
                                {{bookingData.childs.length}} <span v-if="bookingData.childs.length == 1">Bambino</span><span v-else>Bambini</span>,
                            </span>
                            {{bookingData.roomDescription}}
                            <br>
                            {{bookingData.nights}} <span v-if="bookingData.nights == 1">Notte</span><span v-else>Notti</span>
                            <!--
                            <br>
                            Check-in: {{formatDate(bookingData.arrivalDate)}}
                            <br>
                            Check-out: {{formatDate(bookingData.departureDate)}}
                            -->
                            

                            <div v-if="rateAmenities !== 0">
                                {{rateAmenities}}
                            </div>

                            <!--
                            <div v-if="Object.values(bookingData.rateAmenities).length > 0">
                                <br>
                                <span class="room__modal-link">
                                    <span v-for="(at,index) in Object.keys(bookingData.rateAmenities)" v-bind:key="index">
                                        <span v-if="index<3">{{at}}<br></span>
                                        <span v-if="index==3" @click="$bvModal.show('modal-checkout-amenities')" class="terms-link no-print">
                                            <i>Leggi tutto ...</i>                                     
                                        </span>
                                    </span>
                                </span>

                                <b-modal id="modal-checkout-amenities" size="lg" hide-footer>
                                    <template v-slot:modal-title>
                                        Caratteristiche della camera
                                    </template>
                                    <div class="modal-section pt-3">
                                        <div class="property__amenities d-flex flex-wrap">
                                            <span v-for="(at,index) in Object.keys(bookingData.rateAmenities)" v-bind:key="index" :class="'amenity '+(icons.amenities[index] !== undefined ? icons.amenities[index]  : 'icon-default')">
                                                {{at}}
                                            </span>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                            -->

                        </div>


                        <div class="d-flex justify-content-between" v-if="!bookingHidePrice && !mixinGetIntegrationInfo().isGiftCard">
                            <hr />
                            <span>
                                <strong>Totale per il viaggio</strong>
                            </span>
                            <span>{{bookingData.public_price}} €</span>
                        </div>

                        <hr  v-if="!bookingHidePrice && !mixinGetIntegrationInfo().isGiftCard" />

                        <div class="sidebarResumeBlock" v-if="mixinGetIntegrationInfo().mode == 'booking' && fees !== 0">
                            <strong>Costi obbligatori non inclusi</strong>
                            <div class="sidebarRow" v-if="bookingData.totals.mandatory_tax !== 0">
                                <span>Tasse di soggiorno</span>
                                <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
                            </div>
                            <div class="sidebarRow" v-if="bookingData.totals.mandatory_fee !== 0">
                                <span>Spese di pulizia ed utenze</span>
                                <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
                            </div>
                            <div class="sidebarRow" v-if="bookingData.totals.resort_fee !== 0">
                                <span>Resort fee</span>
                                <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
                            </div>
                            <div class="d-flex justify-content-center">
                                <small>Da pagare in struttura al check-in</small>
                            </div>
                        </div>
                        <div class="sidebarResumeBlock" v-else-if="mixinGetIntegrationInfo().mode == 'booking'">

                          <strong>Costi obbligatori non inclusi</strong>
                          &nbsp;
                          <div class="d-flex justify-content-center">
                            La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
                          </div>

                        </div>
                    </div>

                    <hr />

                    <!-- Dati del viaggiatore -->
                    <h5 class="sidebarAccordionTitle">
                        <div v-b-toggle.checkoutStatusUserData>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">1</span> Dati personali <div class="sidebarAccordionIcon"><SvgChevronDown class="chevron" /></div>
                            </span>
                        </div>
                    </h5>

                    <b-collapse id="checkoutStatusUserData" v-model="checkoutStatusUserDataVisible" accordion="checkoutStatus">
                        <div class="sidebarResumeBlock">
                            
                            <p class="text-center"><small>I campi con l'asterisco * sono obbligatori</small></p>

                            <CheckoutPax
                                :inputSize="sidebarBlocks.checkoutPax.inputSize"
                                :ref="sidebarBlocks.checkoutPax.ref"
                                v-if="sidebarBlocks.checkoutPax.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"
                            />

                            <CheckoutBillToPax
                                :inputSize="sidebarBlocks.checkoutBillToPax.inputSize"
                                :ref="sidebarBlocks.checkoutBillToPax.ref"
                                v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"
                            />

                            <CheckoutGiftCard
                                :inputSize="sidebarBlocks.checkoutGiftCard.inputSize"
                                :ref="sidebarBlocks.checkoutGiftCard.ref"
                                v-if="sidebarBlocks.checkoutGiftCard.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"
                                :pax="sidebarBlocks.checkoutPax"
                                :billToPax="sidebarBlocks.checkoutBillToPax"
                            />

                            <div class="checkoutStatusButtons mt-4">
                                <b-button :disabled="!getUserDataStatus()" variant="success" v-b-toggle.checkoutStatusPaymentMethods @click="$emit('fb-px-initiate-checkout',{})">Conferma</b-button>
                            </div>
                        </div>
                    </b-collapse>

                    <!-- OLD --><!-- codici sconto -->
                    <div class="sidebarStep checkoutCodes" v-if="false">
                        <h5 class="sidebarAccordionTitle" v-if="getUserDataStatus()" v-b-toggle.checkoutStatusPaymentMethods>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span>
                                Metodi di pagamento
                            </span>
                            <SvgChevronDown class="chevron" />
                        </h5>
                        <h5 class="sidebarAccordionTitle" v-else>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span>
                                Metodi di pagamento
                            </span>
                        </h5>

                        <b-collapse id="checkoutStatusPaymentMethods" v-model="checkoutStatusPaymentMethodsVisible" accordion="checkoutStatus">
                            <div v-if="!loadingDc" class="sidebarResumeBlock">
                                <div role="tablist">

                                    <!-- Blocco DC -->
                                    <b-form-group>
                                        <div v-if="codes.length > 0">
                                            <div v-for="(code,index) in codes" v-bind:key="'dc_'+index" :class="'sidebarRow discountCodes'">


                                                <div :class="
                                                    'discountCode '
                                                    + (code.usable ? code.valueUsed > 0? 'validCode':'warningCode' : 'invalidCode')
                                                    + ' '
                                                    + (code.usable && !code.divisible && (code.valueUsed == 0) ? 'warningCode ':'')
                                                    + (code.valueUsed == code.valueResidual ? 'fullDot ' : '')
                                                    + (code.valueUsed > 0 & (code.valueUsed < code.valueResidual) ? 'halfDot ' : '')
                                                    + (code.valueUsed == 0 ? 'grayDot ' : '')
                                                    + (!code.usable ? 'redDot ' : '')
                                                ">
                                                    
                                                    <div v-b-toggle="'accordion-discountCode-' + index">
                                                        <div class="discountCodeTitle">
                                                            <div class="d-flex">
                                                                <span class="icon"><SvgEtc /></span>
                                                                <span>
                                                                    Buono sconto
                                                                    <span v-if="!code.divisible && code.status == 'VALID'" v-b-popover.hover.top="'Questo Buono può essere speso solo in modo completo e per una singola prenotazione'">
                                                                        NON frazionabile <SvgInfoCircle class="svgInfo" />
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <SvgChevronDown class="chevron" />
                                                        </div>
                                                        <div class="discountCodeRow">
                                                            <div class="copy copyDots">
                                                                <div class="dcCode">{{code.discountCode}}</div>
                                                            </div>
                                                            <div v-if="code.status != 'EXPIRED'" class="spacer"></div>
                                                            <div v-if="code.status != 'EXPIRED'" class="value"><b>{{mixinRoundTwoDigits(code.valueUsed)}}€</b></div>
                                                        </div>
                                                    </div>

                                                    <b-collapse :id="'accordion-discountCode-' + index" accordion="accordion-discountCodes">

                                                        <!-- gestione errori / informazioni -->
                                                        <div class="discountCodeRow danger" v-if="code.valueUsed == 0">
                                                            <div class="mt-1 mb-1 copy d-flex justify-content-center align-items-center">
                                                                <span class="mr-1">
                                                                    <font-awesome-icon icon="exclamation-triangle"/>
                                                                </span>
                                                                <span v-if="code.status == 'NOT FOUND'">
                                                                    Non abbiamo trovato questo buono sconto. Assicurati di averlo digitato correttamente rispettando MAIUSCOLE  e minuscole
                                                                </span>
                                                                <span v-else-if="code.status == 'EXPIRED'">
                                                                    Buono sconto scaduto il {{code.expirationDate}}
                                                                </span>
                                                                <span v-else-if="code.status == 'EMPTY'">
                                                                    Questo buono sconto è già stato interamente utilizzato per un'altra prenotazione
                                                                </span>
                                                                <span v-else-if="code.status == 'DISABLED'">
                                                                    Questo buono sconto non è attivo, contatta l'assistenza
                                                                </span>
                                                                <span v-else-if="!code.divisible && (code.valueUsed == 0)">
                                                                    Questo buono è di tipo non frazionabile e non può essere speso parzialmente
                                                                </span>
                                                                <span v-else-if="code.status == 'NOT CUMULABLE'">
                                                                    Questo buono sconto non è cumulabile, togli gli altri codici dalla lista per utilizzare questo
                                                                </span>
                                                                <!--<span v-else>
                                                                    Impossibile usare questo buono sconto
                                                                </span>-->
                                                            </div>
                                                        </div>

                                                        <div class="discountCodeRow" v-if="code.cumulable == false">
                                                          <div class="copy" v-b-popover.hover.top="'Questo buono non può essere utilizzato congiuntamente ad altri buoni'">
                                                            <SvgInfoCircle class="svgInfo" /> <b>Non cumulabile</b>
                                                          </div>
                                                        </div>

                                                        <div class="discountCodeRow">
                                                            <div class="copy" v-b-popover.hover.top="'Valore del Buon all\'emissione'">
                                                            <SvgInfoCircle class="svgInfo" /> Valore originario
                                                            </div>
                                                            <div class="spacer"></div>
                                                            <div class="value">{{mixinRoundTwoDigits(code.valueNominal)}}€</div>
                                                        </div>

                                                        <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                        <div class="copy" v-b-popover.hover.top="'In base a possibili utilizzi del Buono sconto per precedenti prenotazioni'">
                                                            <SvgInfoCircle class="svgInfo" /> Valore disponibile
                                                        </div>
                                                        <div class="spacer"></div>
                                                        <div class="value">{{mixinRoundTwoDigits(code.valueResidual)}}€</div>
                                                        </div>

                                                        <!--
                                                        <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                            <div class="copy">
                                                                Già utilizzato
                                                            </div>
                                                            <div class="spacer"></div>
                                                            <div class="value">{{(code.valueNominal - code.valueResidual)*100/100}}€</div>
                                                        </div>
                                                        -->

                                                        <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                            <div class="copy copyDots" v-b-popover.hover.top="
                                                                'Il valore del Buono sconto '
                                                                + (code.valueUsed == code.valueResidual ? 'viene utilizzato in toto per questa prenotazione' : '')
                                                                + (code.valueUsed > 0 & (code.valueUsed < code.valueResidual) ? 'viene utilizzato solo parzialmente per questa prenotazione ' : '')
                                                                + (code.valueUsed == 0 ? 'non verrà utilizzato per questa prenotazione. Resterà valido per altre prenotazioni' : '')
                                                            ">
                                                                <SvgInfoCircle class="svgInfo" /> <b>Utilizzo per questa prenotazione</b>
                                                            </div>
                                                            <div class="spacer"></div>
                                                            <div class="value"><b>{{mixinRoundTwoDigits(code.valueUsed)}}€</b></div>
                                                        </div>

                                                        <div v-if="code.status == 'VALID'" class="discountCodeRow">
                                                            <div class="copy" v-b-popover.hover.top="'Ti invieremo una email con un riepilogo per un futuro utilizzo del residuo'">
                                                                <SvgInfoCircle class="svgInfo" /> Valore disponibile dopo la prenotazione
                                                            </div>
                                                            <div class="spacer"></div>
                                                            <div class="value">{{mixinRoundTwoDigits(code.valueResidual - code.valueUsed)}}€</div>
                                                        </div>

                                                        <div class="discountCodeRemove" @click="removeCode(code.discountCode)">
                                                        <span v-if="code.status=='VALID'">Non utilizzare</span>
                                                        <span v-else>Togli dalla lista</span>
                                                        <span><font-awesome-icon icon="trash"/></span>
                                                        </div>

                                                    </b-collapse>

                                                </div>


                                            </div>
                                        </div>
                                        <div class="sidebarRow" v-if="!thereIsNonCumulableDiscountCode">
                                            <div class="newDiscountCodeBox">
                                                <b-input
                                                    class="newDiscountCode invalid"
                                                    type="text"
                                                    ref="newDiscountCode"
                                                    v-model="newDiscountCode"
                                                    placeholder="es: XXXXXXXXXXXXXXXX"
                                                    @input="validateCode(newDiscountCode,('newDiscountCode'))"
                                                    />
                                                <b-button size="sm" @click="addCode()">AGGIUNGI</b-button>
                                                <p>aggiungi un buono</p>
                                            </div>
                                        </div>
                                    </b-form-group>

                                    <span class="togglePayment black">
                                        <span class="d-flex align-items-center">
                                            <font-awesome-icon icon="money-bill-alt"/> Riepilogo pagamenti
                                        </span>
                                    </span>

                                    <div class="sidebarRow discountCodes">
                                        <div class="dcResume">
                                            <div v-if="availabilityToUse > 0" class="discountCodeRow">
                                              <div class="copy">
                                                <font-awesome-icon icon="money-bill-alt"/> Credito
                                              </div>
                                              <div class="spacer"></div>
                                              <div class="value">{{mixinRoundTwoDigits(availabilityToUse)}} €</div>
                                            </div>
                                            <div v-if="mixinRoundTwoDigits(discountCodesAmount)" class="discountCodeRow">
                                                <div class="copy">
                                                    <SvgEtc /> Codici sconto
                                                </div>
                                                <div class="spacer"></div>
                                                <div class="value">{{mixinRoundTwoDigits(discountCodesAmount)}} €</div>
                                            </div>
                                            <div v-if="mixinRoundTwoDigits(creditCardAmount) > 0" class="discountCodeRow">
                                                <div class="copy">
                                                    <SvgCreditCard /> Carta di credito
                                                </div>
                                                <div class="spacer"></div>
                                                <div class="value">{{mixinRoundTwoDigits(creditCardAmount)}} €</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="checkoutStatusButtons mt-4">
                                    <b-button variant="success" v-b-toggle.checkoutStatusConfirm>Procedi</b-button>
                                </div>
                            </div>
                            <Spinner v-else />
                        </b-collapse>
                    </div>   

                    <hr />
                    
                    <h5 class="sidebarAccordionTitle">
                        <div v-if="getUserDataStatus()" v-b-toggle.checkoutStatusPaymentMethods>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span> Modalità di pagamento <div class="sidebarAccordionIcon"><SvgChevronDown class="chevron" /></div>
                            </span>
                        </div>
                        <div v-else>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span> Modalità di pagamento
                            </span>
                        </div>
                    </h5>

                    <!-- inizio metodi di pagamento -->
                    <b-collapse id="checkoutStatusPaymentMethods" v-model="checkoutStatusPaymentMethodsVisible" accordion="checkoutStatus">

                        <!-- riepilogo per booking con upselling attivo -->
                        <div class="sidebarResumeBlock" v-if="mixinGetIntegrationInfo().mode == 'booking' && mixinGetIntegrationInfo().upselling.enabled">
                            <div class="d-flex justify-content-between">
                                <span>
                                    Salabam {{mixinGetIntegrationInfo().integration.salabam.focus | capitalize}} {{mixinGetIntegrationInfo().integration.salabam.band | capitalize}} <span>{{mixinGetIntegrationInfo().integration.salabam.nights}} <span v-if="mixinGetIntegrationInfo().integration.salabam.nights > 1">notti</span><span v-else>notte</span></span>
                                </span>
                                <span v-if="!bookingHidePrice && !mixinGetIntegrationInfo().isGiftCard">
                                    {{mixinGetIntegrationInfo().integration.salabam.public_price*100/100}} €
                                </span>
                            </div>
                        </div>
                        
                        <!-- riepilogo per booking con upselling NON attivo -->
                        <div class="sidebarResumeBlock" v-if="mixinGetIntegrationInfo().mode == 'booking' && !mixinGetIntegrationInfo().upselling.enabled">
                            <div class="d-flex justify-content-between">
                                <span>
                                    Salabam {{bookingData.focus | capitalize}} {{bookingData.band | capitalize}} <span>{{dates.nights}} <span v-if="dates.nights > 1">notti</span><span v-else>notte</span></span>
                                </span>

                                <span v-if="!bookingHidePrice && !mixinGetIntegrationInfo().isGiftCard">
                                    {{bookingData.public_price}} €
                                </span>
                            </div>
                        </div>

                        <!-- TODO blocco ticket compliments da integrare
                        <CheckoutTicketCompliments
                            :checkoutAmount="checkoutAmount"
                        />
                        -->

                        <!-- NB: reimplementare così:
                            <CheckoutBillToPax
                                :inputSize="sidebarBlocks.checkoutBillToPax.inputSize"
                                :ref="sidebarBlocks.checkoutBillToPax.ref"
                                v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"
                            />
                            -->


                        <div class="panel panel-white welfare" @click="selectPaymentMethod('w')">
                            <div class="head">
                                <div class="icon"><SvgWelfareCheckout /></div>
                                <span>Paga col welfare aziendale</span>
                            </div>
                        </div>
                        <b-collapse id="checkoutPaymentsWelfare" :visible="selectedPaymentMethod == 'w'" class="welfare">
                            <div class="paymentPartnerBlock">
                                <p>
                                    Usare il tuo credito welfare per acquistare questo Salabam è semplice.
                                    Torna sul tuo portale welfare, cerca Salabam e segui le istruzioni.
                                    <br><br>
                                    Basteranno pochi click!
                                </p>
                            </div>
                        </b-collapse>
                    

                        <div v-if="useEtc" class="panel panel-white edenred" @click="selectPaymentMethod('er')">
                            <div class="small-logo head">
                                <SvgEdenred />
                                <span>Ticket Compliments</span>
                            </div>
                        </div>
                        <b-collapse id="checkoutPaymentsEdenred" :visible="selectedPaymentMethod == 'er'" class="edenred">
                            <div class="paymentPartnerBlock">
                                Per usare i tuoi Ticket Compliments fai login con le tue credenziali.
                                <br><br>
                                <EdenredLoginModal :textOnly="'Clicca qui!'" :btnVariant="'success'" />
                            </div>
                        </b-collapse>
                        
                        <div v-if="useScalapay" class="panel panel-white scalapay head" @click="selectPaymentMethod('sp')">
                            <div><img src="/assets/pagamenti/scalapay.svg" /></div>
                            <div class="copy" v-if="scalapayable">
                                3 rate da {{mixinRoundTwoDigits(price/scalapay.numberOfPayments)}} € senza interessi
                            </div>
                            <div v-else class="copy">
                                non disponibile
                            </div>
                        </div>
                        <b-collapse id="checkoutPaymentsScalapay" :visible="selectedPaymentMethod == 'sp'" class="scalapay">
                        
                            <!-- Scalapay -->
                            <div class="paymentPartnerBlock" >
                                <div v-if="scalapayable" class="name etcBlue">
                                    <p>
                                        Prenota subito e paga in <b>3 rate</b> da <b>{{mixinRoundTwoDigits(price/scalapay.numberOfPayments)}} € </b> senza interessi con Scalapay - powered by Stripe.
                                        <br><br>
                                        Prosegui sul sito Scalapay per il pagamento della 1a rata. Potrai usare carte di credito, carte di debito e prepagate.
                                    </p>

                                    <!-- pulsante conferma metodi di pagamento -->
                                    <div class="sidebarResumeBlock">
                                        <div class="checkoutStatusButtons mt-4">
                                            <b-button :disabled="!getPaymentMethodsStatus()" variant="light" v-b-toggle.checkoutStatusConfirm>Prosegui su Scalapay</b-button>
                                        </div>
                                    </div>

                                </div>
                                <div v-else class="name etcBlue">
                                    non è disponibile per questa prenotazione
                                </div>
                            </div>

                            <b-form-group class="paymentPartnerBlock" v-if="!scalapayable">
                                <div class="cannotUseEtcAlert">

                                    <p>
                                        Scalapay permette di rateizzare in {{scalapay.numberOfPayments}} rate senza interessi solo prenotazioni fino a {{scalapay.maxAmount}} € di valore. <a target="_blank" href="https://scalapay.zendesk.com/hc/it/sections/360005291314-Limiti-di-spesa-potere-d-acquisto">Scopri di più sul sito Scalapay.</a>
                                    </p>

                                    <br/><br/>
                                    <router-link :to="{name: 'checkout'}">
                                        <BtnBack text="Paga in altro modo" />
                                    </router-link>

                                    <br/>
                                    <router-link :to="{name: 'homepage'}">
                                        <BtnBack text="Torna alla Home" />
                                    </router-link>

                                </div>

                            </b-form-group>

                        </b-collapse>



                        <!-- satispay -->
                        <div v-if="useSatispay" class="panel panel-white satispay head" @click="selectPaymentMethod('satispay')">
                          <div><img src="/assets/pagamenti/satispay.png" /></div>
                          <div class="copy">
                            Paga con Satispay
                          </div>
                        </div>
                        <b-collapse id="checkoutPaymentsSatispay" :visible="selectedPaymentMethod == 'satispay'" class="satispay">
                          <div class="paymentPartnerBlock" >
                            <div class="name etcBlue">
                              Prenota il tuo soggiorno con Satispay
                            </div>
                            <div class="sidebarResumeBlock">
                              <div class="checkoutStatusButtons mt-4">
                                <b-button :disabled="!getPaymentMethodsStatus()" variant="light" v-b-toggle.checkoutStatusConfirm>Prosegui su Satispay</b-button>
                              </div>
                            </div>
                          </div>
                        </b-collapse>

                        
                        <div v-if="creditCardAmount > 0" class="panel panel-white stripe head" @click="selectPaymentMethod('cc')">
                            <div>
                                <img src="/assets/pagamenti/visa.svg" />
                                <img src="/assets/pagamenti/mastercard.svg" />
                                <img src="/assets/pagamenti/american-express.svg" />
                            </div>
                            <span>Carta di credito</span>
                        </div>
                        <b-collapse id="checkoutPaymentsStripe" :visible="selectedPaymentMethod == 'cc'" class="stripe">
                            <!-- blocco carta di credito (se necessario) -->
                            <div class="paymentPartnerBlock">
                                <Stripe ref="stripe" :amount="creditCardAmount" v-on:stripe-change="stripeChange" v-on:stripe-confirm="stripeConfirm" />
                            </div>
                            <!-- pulsante conferma metodi di pagamento -->
                            <div class="paymentPartnerBlock">
                                <div class="checkoutStatusButtons mt-4">
                                    <b-button :disabled="!getPaymentMethodsStatus()" variant="success" v-b-toggle.checkoutStatusConfirm>Conferma</b-button>
                                </div>
                            </div>
                        </b-collapse>
                        
                        <!-- pulsante conferma metodi di pagamento
                        <div class="sidebarResumeBlock">
                            <div class="checkoutStatusButtons mt-4">
                                <b-button :disabled="!getPaymentMethodsStatus()" variant="success" v-b-toggle.checkoutStatusConfirm>Conferma</b-button>
                            </div>
                        </div>
                        -->

                    </b-collapse>
                    <!-- fine metodi di pagamento -->

                    <hr />                     

                    <!-- Conferma -->
                    <h5 class="sidebarAccordionTitle">
                        <div v-if="getPaymentMethodsStatus()" v-b-toggle.checkoutStatusConfirm>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span> Conferma <div class="sidebarAccordionIcon"><SvgChevronDown class="chevron" /></div>
                            </span>
                        </div>
                        <div v-else>
                            <span class="checkoutDynamicBlockTitle">
                                <span class="stepNumber">2</span> Conferma
                            </span>    
                        </div>
                    </h5>
                    <b-collapse id="checkoutStatusConfirm" v-model="checkoutStatusConfirmVisible" accordion="checkoutStatus">
                        <div class="sidebarResumeBlock">
                            <div class="sidebarRow mb-2">
                                <small v-if="!sidebarBlocks.checkoutGiftCard.status">
                                    Il voucher Salabam verrà inviato all'indirizzo: <b>{{sidebarBlocks.checkoutPax.fields.guestEmail}}</b>
                                </small>
                            </div>

                            <div class="sidebarRow mb-0">
                                <div role="tablist">
                                    <div class="estrattoConto title">
                                        <font-awesome-icon icon="money-bill-alt"/> Riepilogo pagamenti
                                    </div>

                                    <div class="estrattoConto" v-if="selectedPaymentMethod == 'sp'">
                                        <div class="copy">Scalapay</div>
                                        <div class="spacer"></div>
                                        <div class="value">{{price}} €</div>
                                        <div class="info">Clicca su Conferma e prosegui su Scalapay per pagare in <b>{{scalapay.numberOfPayments}} rate</b> da <b>{{mixinRoundTwoDigits(price/scalapay.numberOfPayments)}} € </b> senza interessi con Scalapay - powered by Stripe.</div>
                                    </div>

                                    <div class="estrattoConto" v-if="selectedPaymentMethod == 'satispay'">
                                      <div class="copy">Satispay</div>
                                      <div class="spacer"></div>
                                      <div class="value">{{price}} €</div>
                                      <div class="info">Clicca su Conferma e prosegui per pagare con Satispay</div>
                                    </div>

                                    <div class="estrattoConto" v-if="selectedPaymentMethod == 'cc'">
                                        <div class="copy">Carta di credito</div>
                                        <div class="spacer"></div>
                                        <div class="value">{{mixinRoundTwoDigits(creditCardAmount)}} €</div>
                                        <div class="info">Clicca su Conferma per effettuare il pagamento con carta</div>
                                    </div>

                                    <div class="estrattoConto">
                                        <div class="copy"><strong>Totale</strong></div>
                                        <div class="spacer"></div>
                                        <div class="value">{{price}} €</div>
                                        <!--<div class="info">Clicca su Conferma per effettuuare il pagamento con carta</div>-->
                                    </div>
                                </div>
                            </div>


                            <div class="sidebarRow mb-0">
                                <b-form-group class="mb-0" id="checkout-form">
                                    <div class="privacy-box">
                                        <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                                        <label for="checkbox-privacy">
                                            <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                                <span class="secondary">Ho preso visione delle condizioni d'uso e le accetto</span>
                                            </router-link>
                                        </label>
                                    </div>

                                    <div class="privacy-box">
                                        <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                                        <label for="checkbox-gdpr">
                                            <p class="mb-0">
                                                <a class="secondary" :href="site.urlPrivacy" target="_blank">Ho preso visione della privacy policy e la accetto</a>
                                            </p>
                                        </label>
                                    </div>

                                    <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                                        <label for="checkbox-gdpr">
                                            <p class="checkboxInfo" v-html="disclaimerCheckout"></p>
                                        </label>
                                    </div>

                                    <div v-if="!loading">
                                        <b-button variant="success" v-if="selectedPaymentMethod != 'cc'" class="btn btn-block" :disabled="!canFinallyCheckout()" @click="checkoutConfirm" data-e2e="confirmPurchaseBoxsetButton">
                                            Conferma                                          
                                        </b-button>
                                        <b-button variant="success" v-else class="btn btn-block" :disabled="!canFinallyCheckout()" @click="getIntentClientSecret" data-e2e="confirmPurchaseBoxsetButton">
                                            Conferma
                                        </b-button>
                                    </div>
                                    <Spinner v-bind:msg="loadingText" v-else />
                                </b-form-group>
                            </div>
                        </div>
                    </b-collapse>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import CheckoutPax from './checkout-pax' /* SIDEBAR REFACTOR */
import CheckoutGiftCard from './checkout-gift-card' /* SIDEBAR REFACTOR */
//import CheckoutTicketCompliments from './checkout-' /* SIDEBAR REFACTOR */
//import CheckoutUserInfo from './checkout-user-info' /* SIDEBAR REFACTOR */
//import CheckoutResume from './checkout-resume' /* SIDEBAR REFACTOR */
import Stripe from '../stripe'
import SvgEtc from './../../svg/etc'
import SvgInfoCircle from './../../svg/info-circle'
import SvgCreditCard from './../../svg/credit-card'
import SvgChevronDown from './../../svg/chevron-down'
import SvgBedBlack from './../../svg/bed-black'
//import SvgCross from './../../svg/cross'
//import SvgCheck from './../../svg/check'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import EdenredLoginModal from '@/components/blocks/common/edenred-login-modal'
import SvgEdenred from '@/components/svg/etc-logo-transparent'
import SvgWelfareCheckout from '@/components/svg/welfare-checkout'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrash, faExclamationTriangle, faMoneyBillAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyBillAlt)
library.add(faTrash)
library.add(faExclamationTriangle)
library.add(faPlus)
Vue.component('font-awesome-icon', FontAwesomeIcon)
//import ResumePaymentEtc from './resume-payment-etc'


export default {
    name: 'sidebar-checkout-public',
    components:
    {
        Spinner,
        SvgEtc, SvgInfoCircle, SvgCreditCard, SvgChevronDown, SvgBedBlack, //SvgCross, SvgCheck,
        Stripe,
        CheckoutPax, /* SIDEBAR REFACTOR */
        CheckoutBillToPax, /* SIDEBAR REFACTOR */
        CheckoutGiftCard, /* SIDEBAR REFACTOR */
        //CheckoutTicketCompliments, /* SIDEBAR REFACTOR */
        //CheckoutUserInfo, /* SIDEBAR REFACTOR */
        //CheckoutResume, /* SIDEBAR REFACTOR */
        //ResumePaymentEtc
        BtnBack,
        EdenredLoginModal,
        SvgEdenred,
        SvgWelfareCheckout,
    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() {
              // du sidebar-dc availability viene usata per fare i conti
              // devo gestire il caso "-1" (che a fini di conteggio prezzo và ignorato)
              if(this.$store.state.integration && this.$store.state.integration.availability && this.$store.state.integration.availability > 0){
                return this.$store.state.integration.availability
              }else{
                return 0
              }

            },
            set(value) { this.mixinSendMutation('setAvailability',value) }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
        discountCodes: {
            get() { return this.$store.state.discountCodes },
            set(value) { this.mixinSendMutation('setDiscountCodes',value) }
        },
        dates: {
            get() { return this.$store.state.dates },
            set(value) { this.mixinSendMutation('setDates',value) }
        },
        availabilityToUse: {
          get() {


            // questa computed ritorna quanto credito "availability" è possibile utilizzare per la prentazione
            // (quindi la differenza andrà "addebitata" su cc e/o codici sconto)

            //return (this.availability <= this.checkoutAmount)? this.availability : this.checkoutAmount


            let availability = this.availability;

            if(Vue.prototype.$config.guiSettings.discountCodeResidualAsAvailability){

              /*
              *   su be c'è un'opzione per gestire la disponibilità dei codici sconto come availability
              *   (lbDiscountCodeResidualAsAvailability)
              *
              *   per far quadrare tutti i conti bisogna sottrarre l'ammontare spendibile dei codici sconto
              *   alla disponibilità "gonfiata" dal valore dei codici sconto
              *
              */

              let totDiscountCodesResidual = 0;
              this.codes.forEach(code => {
                totDiscountCodesResidual += parseFloat(code.valueResidual)
              })
              availability -= totDiscountCodesResidual;

            }

            // per il pagamento posso usare tutta la disponibilità o, se maggiore, l'ammontare necessario per la prenotazione
            return (availability <= this.checkoutAmount)? availability : this.checkoutAmount

          },
          set() { }
        },
        qty: {
          get() { return this.qtyInit },
          set(value) {
            this.qtyInit = value
            this.price = this.bookingData.public_price*value
            this.checkoutAmount = this.price
            this.creditCardAmount = this.price - this.availabilityToUse
          }
        },
        scalapayable: {
          get() {

            // ritorna true se la tariffa costa meno (o uguale) al massimo importo rateizzabile con Scalapay
            if(this.mixinRoundTwoDigits(this.price) <= this.scalapay.maxAmount) return true;
            return false;

          },
          set() {},
        },
        scalapayPaymentsSummary: {
          get(){

            let summary = []

            let price = this.mixinRoundTwoDigits(this.price)
            let part = this.mixinRoundTwoDigits(price / this.scalapay.numberOfPayments)

            // gestione del resto
            let calculatedTotal = this.mixinRoundTwoDigits(part * this.scalapay.numberOfPayments);
            let change = this.mixinRoundTwoDigits(price - calculatedTotal)

            for(let i=1; i<=this.scalapay.numberOfPayments; i++){

              let label = 'Oggi'
              if(i>1) label = i + ' rata'

              summary.push({
                label: label,
                amount: part,
              })

            }


            // in genere Scalapay mette i resti in questo modo
            if(change >= 0){
              summary[1].amount = this.mixinRoundTwoDigits(summary[1].amount + change) // eventuale eccedenza sul secondo pagamento
            }else{
              summary[2].amount = this.mixinRoundTwoDigits(summary[2].amount + change) // eventuale resto sull'ultimo pagamento
            }


            // !!! scalapay potrebbe calcolare le rate in modo diverso
            // https://scalapay.zendesk.com/hc/it/sections/360005291314-Limiti-di-spesa-potere-d-acquisto

            return summary;

          },
          set(){},
        },
        thereIsNonCumulableDiscountCode: {
          // scorro tutti i codici e ritorno true se anche solo 1 code.cumulable è false
          get(){
            return this.codes.reduce((temp, code) => {
              return (temp || !code.cumulable)
            }, false)
          },
          set(){},
        },
    },
    data() {        
        return {
            qtyInit: 1,
            icons: Vue.prototype.$config.icons,
            checkoutStatusUserDataVisible: true,
            checkoutStatusPaymentMethodsVisible: false,
            checkoutStatusConfirmVisible: false,
            checkoutStatusPaymentMethods: false,
            //checkoutStatusConfirmOk: false,
            calculating: false,
            canUseDc: true,
            checkoutAmount: 0,
            creditCardAmount: 0,
            discountCodesAmount: 0,
            //ccCollapseOpen: false,
            dcCollapseOpen: false,
            stripeInfoCheck: false,
            status: 'not_accepted',
            displayName: this.mixinGetIntegrationInfo().integration ? Vue.prototype.$config.integration.displayName : '',
            avatarSrc: this.mixinGetIntegrationInfo().integration ? Vue.prototype.$config.integration.avatarSrc : '',
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            loading: false,
            loadingDc: false,
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,
            //beneficiaryId: '',
            //beneficiaries: [],
            
            fees: 0,

            useScalapay: (Vue.prototype.$config.guiSettings.integrations.scalapay.enabled)? true : false,
            scalapay: Vue.prototype.$config.guiSettings.integrations.scalapay,
            selectedPaymentMethod: '',
            useSatispay: (Vue.prototype.$config.guiSettings.integrations.satispay.enabled)? true : false,

            useEtc: (Vue.prototype.$config.guiSettings.integrations.etc.enabled)? true : false,

            codes: [],
            newDiscountCode: '',
            price: 0,
            boxsetAvailability: 0,

            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutPax: {
                    initialize: true,       // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    inputSize: 'lg',
                    ref: 'checkoutPax',
                    status: false,          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}              // campi che vengono eventualmente inviati a bookingData
                },
                checkoutBillToPax: {
                    initialize: false,//true,       //this.mixinCanBillToPax(),
                    inputSize: 'lg',
                    ref: 'checkoutBillToPax',
                    status: false,
                    fields: {}
                },
                checkoutGiftCard: {
                    initialize: Vue.prototype.$config.guiSettings.giftCardsEnabled && this.mixinGetIntegrationInfo().mode == 'boxset' && !this.mixinGetIntegrationInfo().sso, //non va inizializzato in SSO
                    inputSize: 'lg',
                    ref: 'checkoutGiftCard',
                    status: false,
                    fields: {}
                }
            },
            configAmenities: Vue.prototype.$config.amenities,
            rateAmenities: '',
            loadingText: (this.mixinGetIntegrationInfo().mode == 'booking' ? 'Prenotazione in corso... non abbandonare o ricaricare la pagina!' : 'Acquisto in corso... non abbandonare o ricaricare la pagina!'),
            bookingHidePrice: (Vue.prototype.$config.guiSettings.bookingHidePrice),
            site: Vue.prototype.$config.site,

        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {
        this.price = this.bookingData.public_price*this.qty

        if(this.mixinGetIntegrationInfo().mode == 'booking')
        {
            this.boxsetAvailability = this.mixinGetIntegrationInfo().integration.salabam.public_price
        }

        this.validateCodes()

        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if(isJustShown)
            {
                switch (collapseId) {
                    case 'checkoutStatusUserData':
                        this.dcCollapseOpen = false
                        break;
                
                    default:
                        break;
                }   
            }
            
            let accordionButtons = document.querySelectorAll('[aria-expanded="false"]')

            for (let i = 0; i < accordionButtons.length; i++) {
                accordionButtons[i].addEventListener("click", function() {
                    document.querySelectorAll('[aria-expanded="true"]')[0].scrollIntoView({
                        behavior: "smooth"
                    })
                })
            }
        })


        this.checkoutAmount = this.price
        this.creditCardAmount = this.price - this.availabilityToUse
        this.discountCodesAmount = 0

        if(this.mixinGetIntegrationInfo().mode == 'booking')
        {
            this.fees = this.bookingData.totals.mandatory_tax + this.bookingData.totals.mandatory_fee + this.bookingData.totals.resort_fee
            //this.bookingData.payments.salabamConfirmcode = this.mixinGetIntegrationInfo(0).integration.salabam.confirmcode

            this.bookingData.payments.salabam = {
              confirmcode: this.mixinGetIntegrationInfo().integration.salabam.confirmcode,
              email: this.mixinGetIntegrationInfo(0).integration.salabam.email,
            }

            this.creditCardAmount = Math.max(0,(this.price - parseFloat(this.mixinGetIntegrationInfo().integration.salabam.public_price)))
        }

        this.sidebarBlocks.checkoutBillToPax.initialize = (this.creditCardAmount > 0)

    },
    methods:
    {

        selectPaymentMethod(method)
        {
            if(method == this.selectedPaymentMethod)
            {
                this.selectedPaymentMethod = ''
                return
            }

            this.selectedPaymentMethod = method
        },


        /* SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto */
        sidebarBlockChange(e){
            this.sidebarBlocks[e.name].fields = e.fields
            this.sidebarBlocks[e.name].status = e.status

            // se cambio qualcosa in pax o billToPax resetto le informazioni dentro giftCardShipping per evitare in
            if(this.sidebarBlocks.checkoutGiftCard.initialize)
            {

                if(process.env.VUE_APP_MODE != 'production') window.console.log('sidebar-dc sidebarBlockChange -> status')

                if(e.name == 'checkoutPax' || e.name == 'checkoutBillToPax')
                {
                    this.$refs.checkoutGiftCard.updatePaxAndBillToPaxFields() //aggiro un caso limite in cui la reattività di vue non arriva alle chiavi di un oggetto che arriva come prop ma viene poi copiato dentro data()
                }

            }
            this.getUserDataStatus()
        },
        validateCodes()
        {
            let _this = this,
                existingCodes = ""

            _this.loadingDc = true

            if(_this.discountCodes && _this.discountCodes.length == 0)
            {
                _this.codes = []
                _this.updateTotals()
                //_this.mixinSendMutation('setAvailability', 0) // non ci sono codici, zero credito
                _this.loadingDc = false
                return
            }

            _this.discountCodes.forEach(function(code,index){
                existingCodes += code
                if(index < (_this.discountCodes.length - 1)) { existingCodes += ","}
            })

            Api
                .dcCheck({
                    discountCodes: existingCodes,
                    token: this.$store.state.token
                    //discountCodes: "aX1IyMEkgedE7EV5,sklWXmhTaoeme1yx,KbP56x5c2YU5TIzw,7FbtMRKeoMJI5NuY,1rrXrFzsbyviiL2U,fCV5obs9ZBBwiQn6,oRQs4HarkHcfNvka"
                })
                .then((response) => {

                    if(response.updateAvailabilityTo) _this.mixinSendMutation('setAvailability',response.updateAvailabilityTo)

                    _this.codes = Object.values(response.discountCodes)
                    _this.updateTotals()
                    _this.loadingDc = false
                })

        },
        validateCode(newDiscountCode,ref)
        {
            this.mixinValidateFields([
                {
                    ref: this.$refs[ref],
                    type: 'discountCode',
                    value: newDiscountCode,
                }
            ])
        },
        addCode()
        {
            if(!this.discountCodes.includes(this.newDiscountCode))
            {
                this.discountCodes.push(this.newDiscountCode)
                this.mixinSendMutation('setDiscountCodes',this.discountCodes)
            }
            this.newDiscountCode = ''
            this.validateCodes()
        },
        removeCode(discountCodeToRemove)
        {
            //if(!this.canUseDc) return

            this.loadingDc = true

            //this.codes.splice((index),1)
            this.codes.forEach( (code, index) => {

              if(code.discountCode == discountCodeToRemove){
                this.codes.splice(index, 1)
                return
              }

            }) // each discount codes


            //this.discountCodes.splice(index,1)
            let dcIndex = this.discountCodes.indexOf(discountCodeToRemove);
            if (dcIndex > -1) {
              this.discountCodes.splice(dcIndex, 1);
            }


            this.mixinSendMutation('setDiscountCodes',this.discountCodes)
            this.newDiscountCode = ''

            this.validateCodes()
            //this.loadingDc = false
        },
        updateTotals()
        {
            this.loadingDc = true
            this.discountCodesAmount = 0 // ammontare usato con i codici sconto
            let _this = this

            // salvo il prezzo della prenotazione
            // e man a mano che uso codici lo scalo così so quanto mi occorre dai codici
            let tempCheckoutAmount =  _this.checkoutAmount - _this.availabilityToUse

            this.codes.forEach(code => {

                if(typeof(code.valueNominal) == 'string') code.valueNominal = parseInt(code.valueNominal)
                if(typeof(code.valueResidual) == 'string') code.valueResidual = parseInt(code.valueResidual)

                code.valueUsed = 0 // serve per l'interfaccia

                if(tempCheckoutAmount == 0) return // già raggiunto il pagamento


                if(!code.usable) {
                    return
                }


                if(!code.divisible && code.valueResidual <= tempCheckoutAmount){
                  // non frazionabile di importo pari o inferiore a quanto resta da pagare

                  code.valueUsed = code.valueResidual
                  tempCheckoutAmount -= code.valueResidual
                  _this.discountCodesAmount += code.valueUsed
                  return

                } // if ! divisible


                if(code.divisible){
                  // frazionabile

                  if(code.valueResidual <= tempCheckoutAmount){

                    // importo minore uguale, uso tutto

                    code.valueUsed = code.valueResidual
                    tempCheckoutAmount -= code.valueResidual

                    _this.discountCodesAmount += code.valueUsed
                    return


                  }else if(code.valueResidual > tempCheckoutAmount){

                    // importo maggiore, uso quanto basta

                    code.valueUsed = tempCheckoutAmount
                    tempCheckoutAmount -= tempCheckoutAmount // praticamente farà sempre 0, ma così è più coerente

                    _this.discountCodesAmount += code.valueUsed
                    return

                  }

                } // if divisible


            }) // each discount codes

            this.discountCodesAmount = _this.discountCodesAmount
            this.creditCardAmount = Math.max(this.checkoutAmount - this.discountCodesAmount - this.availabilityToUse, 0)
            this.loadingDc = false
        },
        getIntentClientSecret()
        {
            if(!this.stripeInfoCheck)
            {
                //this.ccCollapseOpen = true
                return
            }
            
            this.loading = true

            // paga solo con codici sconto
            if(this.discountCodesAmount == this.checkoutAmount)
            {
                this.checkoutConfirm()
                return;
            }

            // paga con carta di credito e, opzionalmente, con codici sconto
            let stripeName = this.$refs.stripe.$refs.ccname.value
            if(stripeName == '')
            {
                this.loading = false
                //this.ccCollapseOpen = true
                this.dcCollapseOpen = false
                return
            }
            this.$refs.stripe.getIntentClientSecret()
        },
        stripeChange(e)
        {
            this.stripeInfoCheck = e
        },
        stripeConfirm(e)
        {
            if(e.error)
            {
                this.loading = false
                let data = {
                    msg: e.message,
                    status: e.status,
                    callback: function() {}
                }
                //data.title = ''
                //data.closeText = ''
                data.msg = e.result.message
                window.eventBus.$emit('gotNotification',data)
                return
            }

            this.bookingData.payments.cc.paymentIntentId = e.paymentIntentId
            this.bookingData.payments.cc.nameSurname = e.name
            this.checkoutConfirm()
        },
        checkoutConfirm()
        {
            this.loading = true
            
            let discountCodes = {
                amount: this.discountCodesAmount,
                codes: []
            }

            this.codes.forEach(function(code){
                if(code.usable)
                {
                    discountCodes.codes.push({
                        discountCode: code.discountCode,
                        amount: code.valueUsed,
                        valueNominal: code.valueNominal,
                        valueResidual: code.valueResidual, // prima della prenotazione
                        expirationDate: code.expirationDate,
                    })
                }
            })


            this.bookingData.payments.scalapay = {}
            this.bookingData.payments.satispay = {}

            if(this.selectedPaymentMethod == 'cc')
            {
                this.bookingData.payments.welfare.amount = this.availabilityToUse // "welfare" indica "credito integrazione"
                this.bookingData.payments.scalapay.amount = 0
                this.bookingData.payments.cc.amount = this.creditCardAmount
            }


            if(this.selectedPaymentMethod == 'sp')
            {
                this.bookingData.payments.cc.amount = 0
                this.bookingData.payments.scalapay.amount = this.creditCardAmount // totale pagamento utente pubblico*/
            }

            if(this.selectedPaymentMethod == 'satispay')
            {
              this.bookingData.payments.cc.amount = 0
              this.bookingData.payments.satispay.amount = this.creditCardAmount // totale pagamento utente pubblico*/
            }


            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            parameters.bookingData = bookingData
            parameters.token = _this.token

            // usato (per ora) solo per le gift card
            parameters.qty = _this.qty

            parameters.boxset_id = parameters.bookingData.boxset_id
            parameters.focus = parameters.bookingData.focus
            parameters.band = parameters.bookingData.band

            parameters.guestEmail = _this.sidebarBlocks.checkoutPax.fields.guestEmail
            parameters.guestEmailConf = _this.sidebarBlocks.checkoutPax.fields.guestEmailConf
            parameters.guestPhone = _this.sidebarBlocks.checkoutPax.fields.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy
            parameters.beneficiaryId = 0
            parameters.guestFirstName = _this.sidebarBlocks.checkoutPax.fields.guestFirstName
            parameters.guestLastName = _this.sidebarBlocks.checkoutPax.fields.guestLastName

            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestRagioneSociale = _this.sidebarBlocks.checkoutBillToPax.fields.guestRagioneSociale
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode

                parameters.guestVat = _this.sidebarBlocks.checkoutBillToPax.fields.guestVat
                parameters.guestSdi = _this.sidebarBlocks.checkoutBillToPax.fields.guestSdi


                this.bookingData.payments.billing = {
                  guestFirstName: _this.sidebarBlocks.checkoutPax.fields.guestFirstName,
                  guestLastName: _this.sidebarBlocks.checkoutPax.fields.guestLastName,
                  guestEmail: _this.sidebarBlocks.checkoutPax.fields.guestEmail,
                  guestFiscalCode: _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode,
                  guestPhone: _this.sidebarBlocks.checkoutPax.fields.guestPhone,
                  guestAddress: _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress,
                  guestCity: _this.sidebarBlocks.checkoutBillToPax.fields.guestCity,
                  guestPostalCode: _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode,
                  guestProvince: _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince,
                  guestVat: _this.sidebarBlocks.checkoutBillToPax.fields.guestVat,
                  guestSdi: _this.sidebarBlocks.checkoutBillToPax.fields.guestSdi,
                }

            }

            if(this.sidebarBlocks.checkoutGiftCard.initialize)
            {
                if(Vue.prototype.$config.guiSettings.giftCardsEnabled && this.$refs.checkoutGiftCard.isGift)
                {
                    parameters.gift = {
                        destinatarioNome: this.sidebarBlocks.checkoutGiftCard.fields.giftCardFirstName,
                        destinatarioCognome: this.sidebarBlocks.checkoutGiftCard.fields.giftCardLastName,
                        destinatarioEmail: this.sidebarBlocks.checkoutGiftCard.fields.giftCardEmail,
                        // specifico per invio cartaceo
                        // shipping: {}
                    }

                    // specifico per invio email
                    if(this.sidebarBlocks.checkoutGiftCard.fields.mode == 'email')
                    {
                        parameters.gift.msg = this.sidebarBlocks.checkoutGiftCard.fields.giftCardMessage
                    }

                    if(this.sidebarBlocks.checkoutGiftCard.fields.mode == 'box')
                    {
                        parameters.gift.shipping = {
                            nome: this.sidebarBlocks.checkoutGiftCard.fields.giftCardShippingFirstName,
                            cognome: this.sidebarBlocks.checkoutGiftCard.fields.giftCardShippingLastName,
                            ragioneSociale: parameters.guestRagioneSociale ? parameters.guestRagioneSociale : '', // se billToPax è disattivato lo mando vuoto
                            co: this.sidebarBlocks.checkoutGiftCard.fields.giftCardCo,
                            indirizzo: this.sidebarBlocks.checkoutGiftCard.fields.giftCardAddress,
                            cap: this.sidebarBlocks.checkoutGiftCard.fields.giftCardPostalCode,
                            citta: this.sidebarBlocks.checkoutGiftCard.fields.giftCardTown,
                            provincia: this.sidebarBlocks.checkoutGiftCard.fields.giftCardProvince,
                            paese: this.sidebarBlocks.checkoutGiftCard.fields.giftCardCountry,
                            cell: this.sidebarBlocks.checkoutGiftCard.fields.giftCardPhone,
                        }
                        parameters.gift.destinatarioEmail = this.sidebarBlocks.checkoutGiftCard.fields.giftCardBoxEmail
                    }
                }
            }

            let endpoint = this.mixinGetIntegrationInfo(0).mode == 'boxset' ? 'checkoutBoxset' : 'checkoutSalabam'
            Api[endpoint](parameters)
                .then((response) => {

                    // se ci sono errori riattivo il tasto prenota
                    // salvo il caso in cui sia valorizzato ignoreNotificationModal (se valorizzato il be
                    // probabilmente sta rispondendo tramite la modal per fare redirect)
                    if(response.data.errors && !response.data.ignoreNotificationModal) _this.loading = false

                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)
                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId
                        return _this.checkoutConfirm()
                    }

                    if(response.status == 200)
                    {

                        _this.mixinSendMutation('setAvailability', response.data.updateAvailabilityTo)

                        if(this.$gtm) this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'userBehaviour',
                            action: 'confirmedOrder',
                            label: response.data.email,
                            value: parameters.public_price,
                        })


                        if(this.$gtm && Vue.prototype.$config && Vue.prototype.$config.integration && Vue.prototype.$config.integration.company) this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'confirmedOrder',
                            label: Vue.prototype.$config.integration.company,
                            value: parameters.public_price,
                        });

                        if(window.LogRocket) window.LogRocket.track('confirmedOrder')


                        this.$emit('sidebar-order-confirmed', {qty: this.qty})


                        // redirect su cofanetto
                        if (this.mixinGetIntegrationInfo(0).mode == 'boxset')
                        {
                            _this.$router.push({
                                name: 'confirm-boxset',
                                params: {
                                    integratedOrderId: response.data.integratedOrderId,
                                    parsedEmailId: response.data.parsedEmailId,
                                    //newAffiliateReferenceId: response.data.newAffiliateReferenceId,
                                    redirectIfConfirmedRedirectTo: false
                                }
                            })
                        }
                        // redirect su prenotazione
                        else
                        {

                            _this.$router.push({
                                name: 'confirm',
                                params: {
                                    confirmcode: response.data.confirmcode,
                                    itinerary_id: response.data.itinerary_id,
                                    newAffiliateReferenceId: response.data.newAffiliateReferenceId,
                                    redirectIfConfirmedRedirectTo: true
                                }
                            })
                        }
                    }
                })
        },
        getUserDataStatus()
        {
            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutPax.initialize)
            {
                if(this.sidebarBlocks.checkoutPax.status == false) return false
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                if(this.sidebarBlocks.checkoutBillToPax.status == false) return false
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutGiftCard.initialize)
            {
                if(this.sidebarBlocks.checkoutGiftCard.status == false) return false
            }

            return true
        },
        getPaymentMethodsStatus()
        {
            if (!this.getUserDataStatus()) return false

            // criterio di pagamento non impostato
            if(this.selectedPaymentMethod == '') return false

            // cassetto welfare aperto
            if(this.selectedPaymentMethod == 'w') return false

            // se ho il cassetto con edenred aperto
            if(this.selectedPaymentMethod == 'er') return false

            // se sto pagando con carta
            if(this.selectedPaymentMethod == 'cc')
            {
                // criterio di controllo pagamento con carta
                if(this.creditCardAmount > 0 && !this.stripeInfoCheck) return false
            }

            // se sto pagando con scalapay
            if(this.selectedPaymentMethod == 'sp')
            {
                // eventuale criterio di controllo pagamento con scalapay non implementato: è tutto calcolato in background
            }

            if (this.loadingEtc) return false
            if (!this.canUseDc) return true
            if (this.boxsetAvailability >= this.checkoutAmount) return true
            if (this.mixinRoundTwoDigits(this.discountCodesAmount + this.availabiliy) > this.checkoutAmount) return false
            return true
        },
        canFinallyCheckout()
        {
            if (!this.getUserDataStatus()) return false           // ridondante, per sicurezza
            if (!this.getPaymentMethodsStatus()) return false
            /*
            if (this.boxsetAvailability >= this.checkoutAmount) return true
            if(this.mixinRoundTwoDigits(this.discountCodesAmount + this.availabilityToUse) < this.checkoutAmount)
            {
                if (!this.stripeInfoCheck) return false
            }
            */
            if (!this.guestPrivacy) return false
            if (!this.guestGdpr) return false
            return true
        },

        formatDate: function(data){
          function pad(s) { return (s < 10) ? '0' + s : s; }
          var d = new Date(data)
          return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('-')
        },

        rateAmenitiesTxt: function(){
          if(this.bookingData.rateAmenities.length == 0){
            this.rateAmenities = 'solo pernotto'
          }else{
            let amenities = '';
            for (let key in this.bookingData.rateAmenities) {
              if(this.configAmenities[key]) amenities += this.configAmenities[key].label + ', '
            }

            // capitalizzo e tolgo ", " dalla coda (-3 perchè sto accorciando la stringa senza il primo char)
            amenities = amenities.charAt(0).toUpperCase() + amenities.slice(1).substr(0, amenities.length -3)

            this.bookingData.rateAmenitiesTxt = amenities // alza warning, rateAmenitiesTxt lo uso solo qui per passarmelo al backend.. in seguito vedremo
            this.rateAmenities = amenities
          }
        },
    },
    created: function() {
      this.rateAmenitiesTxt();
    },
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    #checkoutStatusPaymentMethods
    {
        .panel-white
        {
            padding:0 $grid-gutter-width/4;
            width:100%;
            display:flex;
            align-content: center;
            justify-content: center;
        }

        .paymentContent
        {
            padding-left: 0;
            padding-right: 0;
            padding-top:0;
            .btn
            {
                display:block;
                width:100%;
                @extend .btn-primary !optional;
            }
        }

        .paymentPartnerBlock
        {
            margin-bottom:$grid-gutter-width/2;
            font-size: 15px;
            *
            {
                font-size: 15px;
            }
            .btn
            {
                display:block;
                width:100%;
            }
        }

        .edenred
        {
            .edenredLoginModal
            {
                width:100%;
                &>div
                {
                    display:block;
                    width:100%;
                }
            }
        }

        .scalapay
        {

            &.head
            {
                width:100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                
                & > div {
                    margin-right: 15px;
                }

                img
                {
                    width:auto;
                    height:16px;
                }
                *
                {
                    font-weight:bold;
                    line-height: normal;
                }
            }

            .btn,
            .btn:hover,
            .btn:focus
            {
                margin:0;
                padding:0;
                border:0;
                display: inline;
                width:auto;
                font-weight: bold;
                text-decoration: underline;
                background:transparent;
                text-align:left;
            }
        }

        .satispay
        {

          &.head
          {
            width:100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;

            & > div {
                margin-right: 15px;
            }

            img
            {
              width:auto;
              height:19px;
            }
            *
            {
              font-weight:bold;
              line-height: normal;
            }
          }

          .btn,
          .btn:hover,
          .btn:focus
          {
            margin:0;
            padding:0;
            border:0;
            display: inline;
            width:auto;
            font-weight: bold;
            text-decoration: underline;
            background:transparent;
            text-align:left;
          }
        }

        .edenred
        {
            .small-logo
            {
                width:100%;
                
                svg
                {
                    width: 120px;
                    margin-right: 15px;
                }

                *
                {
                    font-weight:bold;
                }
                display:flex;
                align-items: center;
                justify-content: space-between;
            }

            .edenredLoginModal,
            .textOnly
            {
                display:inline;
            }
        }

        .stripe
        {
            &.head
            {
                & > div {
                    margin-right: 15px;
                }

                img
                {
                    width:auto;
                    height:24px;
                    margin-right:8px;
                }
                width:100%;
                display:flex;
                align-items: center;
                justify-content: space-between;
                *
                {
                    font-weight:bold;
                    line-height: normal;
                }
            }
        }

        .welfare
        {
            .head
            {
                width:100%;
                display:flex;
                align-items: center;
                justify-content: space-between;
                *
                {
                    font-weight:bold;
                }
                .icon {
                    margin-right: 15px;
                }
                svg
                {
                    width: auto !important;
                    height: 48px !important;
                    margin-top:5px;
                    //margin-left:2px;
                }
            }
        }

        .head
        {
            font-size:14px;
            font-weight:bold;
            cursor:pointer;
        }

        .panel
        {
            min-height:68px;
            margin-bottom:$grid-gutter-width/2;
        }
    }

    .sidebarCheckoutPublic
    {
        .privacy-box
        {
            font-size: 14px;
            line-height: 120%;            
        }

        .estrattoConto
        {
            padding-left: 2.5rem;
            display:flex;
            flex-wrap:wrap;
            margin-bottom:1rem;

            &.title
            {
                padding-left: 0;
                color:$black;
                min-width:100%;
                display:flex;
                align-items: center;
                font-size: 18px;
                //font-weight: bold;
                margin-bottom:1rem;
                svg
                {
                    margin-right:1rem;
                    margin-right: 1rem;
                    height: 1.25rem;
                    width: auto;                    
                }
            }

            .copy
            {
                line-height: 1;
            }

            .spacer
            {
                border-bottom:1px solid $black;
                flex:1;
                margin-left:0.25rem;
                margin-right:0.25rem;
                line-height: 1;
            }

            .value
            {
                font-weight: bold;
                line-height: 1;
            }

            .info
            {
                min-width:100%;
                font-size:75%;
                padding-top: 0.5rem;
                text-align: left;
                line-height: 120%;      
            }
        }
    }

</style>